@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --body-color-bg: #f1f1f1;
  --main-color-one: #959fc2;
  --primary-color: #536872;
  --primary-hover-color: #708090;
  --secondary-color: #3a4955;
  --heading-color: #576481;
  --paragraph-color: #666e8d;
  --muted-color: #bcbec2;
  --footer-color: #dddddd;
  --highlight-bg-color: #e4e4e4;
  --menuitem-color: #fff;
  --reverse-color: #36454f;
  --danger-color: #ee0e21;
  --heading-font: "Montserrat", sans-serif;
  --body-font: "Poppins", sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* overflow-x: hidden; */
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
}

* {
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox  */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  margin: 0;
  background-color: #f1f1f1;
  background-color: var(--body-color-bg);
  color: #666e8d;
  color: var(--paragraph-color);
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.95rem;
}

html,
body {
  height: 100%;
}

.h1 {
  font-size: 48px;
  line-height: 1.0833333333333333;
}

.h2 {
  font-size: 36px;
  line-height: 1.4444444444444444;
}

.h3 {
  font-size: 24px;
  line-height: 1.0833333333333333;
}

.h4 {
  font-size: 20px;
  line-height: 1.2380952380952381;
}

.h5 {
  font-size: 16px;
  line-height: 1.2380952380952381;
}

.h6 {
  font-size: 14px;
  line-height: 1.2380952380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #576481;
  color: var(--heading-color);
  font-family: "Montserrat", sans-serif;
  font-family: var(--heading-font);
  margin: 0;
}

p {
  color: #666e8d;
  color: var(--paragraph-color);
  /*     -webkit-hyphens: auto;
-moz-hyphens: auto;
-ms-hyphens: auto;
hyphens: auto; */
  margin-bottom: 15px;
  text-align: justify;
}

a {
  color: inherit;
  text-decoration: none;
  color: #666e8d;
  color: var(--paragraph-color);
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li {
  list-style: none;
  margin-bottom: 1rem;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}

.text-muted {
  color: #bcbec2 !important;
  color: var(--muted-color) !important;
}


/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  border: 3px solid #969696;
}

.form-control.file {
    border: none !important;
}

code {
    color: #faa603;
}

.dark-bg {
    background-color: #111d5c;
}

.circle-icon {
    background: #536872;
    background: var(--primary-color);
    color: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
    padding: 10px;
    font-size: 24px;
}

.form-error {
  color: #df2926;
}

.form-control::-webkit-input-placeholder {
  color: #b5b5b5;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #b5b5b5;
  opacity: 1;
}

.form-control::placeholder {
  color: #b5b5b5;
  opacity: 1;
}

small.form-error {
  font-size: 0.7rem;
}


.btn {
  padding: 0.6rem 1.75rem;
  font-size: 0.75rem;
  border-radius: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.65rem;
}

.form-group label {
  font-weight: 600;
  color: #536872;
  color: var(--primary-color);
}


/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/

.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}


/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/

.clear:before,
.clear:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after {
  clear: both;
}


/*--------------------------------------------------------------
# Font size
--------------------------------------------------------------*/
small, .small {
  font-size: 70%;
  line-height: 1.0 !important;
}

small.error {
  color: #df2926;
}

.f10 {
  font-size: 10px;
}

.f12 {
  font-size: 12px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}


/*--------------------------------------------------------------
# Margin
--------------------------------------------------------------*/

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-30 {
  margin-top: 30px;
}


/*--------------------------------------------------------------
# Border
--------------------------------------------------------------*/

.bor-tl-rad {
  border-top-left-radius: 7px;
}

.bor-tr-rad {
  border-top-right-radius: 7px;
}

.bor-br-rad {
  border-bottom-right-radius: 7px;
}

.bor-bl-rad {
  border-bottom-left-radius: 7px;
}

.bor-rad {
  border-radius: 7px;
}


/*--------------------------------------------------------------
# Breadcrumb
--------------------------------------------------------------*/

.breadcrumb {
  background-color: transparent;
  padding: 5px 0 0 63px;
  margin: 0;
}

.breadcrumb li {
  list-style-type: none;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.breadcrumb li::before {
  counter-increment: none;
  content: "";
  margin-right: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #bcbec2;
  color: var(--muted-color);
}

.breadcrumb-item.active {
  color: #bcbec2;
  color: var(--muted-color);
}

.breadcrumb li a {
  color: #536872;
  color: var(--primary-color);
}


/*--------------------------------------------------------------
# CENTER ALIGNED CONTAINER
--------------------------------------------------------------*/

.centerParentContaniner {
  display: table;
  height: 100%;
  margin: 0 auto;
}

.parentContainer {
  display: table-cell;
  vertical-align: middle;
}

.login-screen {
  background: url(/static/media/login_bg.3eef3a0c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  height: 100vh;
}

.login {
  max-width: 480px;
}

.login-brand {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.login-input {
  height: 40px;
  border: 3px solid #3a4955;
  font-size: 14px;
}

.btn-custom {
  padding: 8px 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  background-color: #3a4955;
  background-color: var(--secondary-color);
}

.btn-custom:hover {
  color: #fff;
}

.navbar {
  margin-bottom: 15px;
}
.nav-link, .nav-item {
  font-size: 0.8rem;
  color: #fff !important;
  color: var(--menuitem-color) !important;
}

.dropdown img {
  width: 24px;
}

.dropdown-menu {
  font-size: 0.8rem;
  padding: 0;
  border-radius: 0;
}

.right .dropdown-menu.show {
  left: unset;
  right: 0 !important;
}
.dropdown-item {
  padding: .25rem .7rem;
}  
.dropdown-toggle::after {
  content: unset;
}

.page-content {
  margin-top: 72px;
  min-height: 85vh;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 7px 0;
}
.footer h5 {
  color: #dddddd;
  color: var(--footer-color);
}


.search-data-card {
  background: #fff;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 6px;
  margin-bottom: 15px;
}

.shadow {
  box-shadow: 0px 3px 5px 0px rgba(176, 176, 176, 0.7);
}

.dcotor-profile-schedule {
  position: relative;
}
.schedule-item {
  width: 100%;
  background: #cfe3ff;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.schedule-item .desc span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}  
.schedule-item .action {
  padding: 0.45rem 0.75rem;
  font-size: 0.65rem;
  border-radius: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.profile-card {
  position: relative;
  background: #fff;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 6px;
/*   margin-top: 40px; */
  margin-bottom: 15px;
}

.profile-card p {
  font-size: 14px;
}

.profile-card img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin-top: 10px;
}

.avatar-container {
  position: relative;
}
.avatar-container img {
  opacity: 1;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border: 3px solid #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.avatar-change-handle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 52%;
  -webkit-transform: translate(-55%, -55%);
          transform: translate(-55%, -55%);
  -ms-transform: translate(-55%, -55%);
  text-align: center;
}
.avatar-container:hover {
  cursor: pointer;
}
.avatar-container:hover img {
  opacity: 0.3;
}

.avatar-container:hover .avatar-change-handle {
  opacity: 1;
}

.round-icon {
  background: #cfe3ff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 12px;
  padding: 5px;
  text-align: center;
  line-height: 14px;
  vertical-align: middle;
  margin-right: 15px;
}

.profile-card .action{
    position: absolute;
    width: auto;
    top: 0.05rem;
    right: -0.05rem;
} 

.btn-action {
  background: #536872;
  background: var(--primary-color);
  padding: 3px 12px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
} 

.tab-menu a {
  color: #3a4955 !important;
  color: var(--secondary-color) !important;
}
.tab-menu a.active {
  background: #36454f !important;
  background: var(--reverse-color) !important;
  color: #ffffff !important;
}

.weekdates {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}
.weekdates .btn {
  padding: 0;
  width: 120px;
  margin: 0 15px 15px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.appointment-slots {
  width: 100%;
}
.slot {
  background: #cfe3ff;
  border: 1px solid #007bff;
  border-radius: 6px;
  padding: 7px;
  margin: 0 15px 15px 0;
  width: 90px;
  height: 48px;
  font-size: 14px;
  font-weight: 700;
/*   color: #007bff;
 */  text-align: center;
  line-height: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.slot:hover {
  background: #007bff;
  color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.action-btn {
  background: #cfe3ff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  line-height: 24px;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.action-btn.danger {
  background: #dc3545 !important;
  color: #ffffff;
}

